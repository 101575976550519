<script lang="ts" setup>
import type { VisaTestimonial } from "~/types/rest_api_types/pages/services";

defineProps<{
  testimonials: VisaTestimonial[];
}>();

function filterContent(content: string) {
  if (content === "-")
    return "";

  return content;
}
</script>

<template>
  <div class="p-1">
    <UCard
      v-for="(testimonial, index) in testimonials"
      :key="index"
      :ui="{
        base: 'h-full relative pb-24',
        body: {
          padding: 'sm:p-6',
        },
      }"
    >
      <div class="flex flex-col ">
        <span
          v-external-link
          class="line-clamp-6 text-lg"
          v-html="testimonial.review"
        />

        <div class="absolute inset-x-6 bottom-6 border-t py-2">
          <div class="flex items-center">
            <NuxtPicture
              v-if="testimonial.avatar.url"
              :src="testimonial.avatar.url"
              :alt="`${testimonial.firstname} ${testimonial.lastname}, ${testimonial.title} @ ${testimonial.company}`"
              sizes="(max-width: 640px) 48px, (max-width: 768px) 48px, (max-width: 1024px) 48px, (max-width: 1280px) 48px, (max-width: 1536px) 48px, 48px"
              width="48"
              height="48"
              :img-attrs="{ class: 'mr-4 object-cover rounded-full' }"
              provider="cloudflare"
            />

            <div>
              <p class="font-semibold">
                {{ testimonial.firstname }} {{ testimonial.lastname }}<span
                  v-if="filterContent(testimonial.title)"
                  class="font-medium"
                >, {{ filterContent(testimonial.title) }}</span>
              </p>
              <p class="text-sm">
                {{ filterContent(testimonial.company) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </UCard>
  </div>
</template>
